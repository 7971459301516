import React, { useState } from 'react'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'
import './tab-store.scss'

const StoreLocationTabs = ({ filterCountry, menuCountry }) => {
    // Set default active country
    // const defaultActiveCountry = 'Singapore'
    const [activeCountry, setActiveCountry] = useState('Australia') // State to manage active country
    const sortedMenuCountry = [...menuCountry].sort((a, b) => b.localeCompare(b))

    const renderNavLinks = () => {
        return sortedMenuCountry.map((country, index) => (
            <Nav.Link
                key={index}
                eventKey={country}
                onClick={() => filterCountry(country)}
            >
                {country}
            </Nav.Link>
        ))
    }

    const renderDropdownItems = () => {
        return sortedMenuCountry.map((country, index) => (
            <Dropdown.Item
                key={index}
                onClick={() => {
                    filterCountry(country)
                    setActiveCountry(country) // Set active country when clicked
                }}
                className={activeCountry === country ? 'active' : ''}
            >
                {country}
            </Dropdown.Item>
        ))
    }

    return (
        <div className="menu-country-store">
            <Navbar bg="none" expand="md" className="d-md-block d-none">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                        className="navigation-box mont-regular"
                        defaultActiveKey="Australia"
                    >
                        {renderNavLinks()}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Dropdown className="d-md-none d-block">
                <Dropdown.Toggle
                    id="dropdown-basic"
                    className="subcat-list btn-primary__fill-pink"
                >
                    {activeCountry}
                </Dropdown.Toggle>
                <Dropdown.Menu>{renderDropdownItems()}</Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default StoreLocationTabs
