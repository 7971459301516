import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const SearchStore = ({ search, searchTerm }) => {
    return (
        <Form className="box-form">
            <Form.Row>
                <InputGroup
                    controlId="search"
                    className="mt-3 mont-regular box-search"
                >
                    <Form.Control
                        type="text"
                        placeholder="Search Store Name..."
                        value={searchTerm}
                        onChange={search}
                    />
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                </InputGroup>
            </Form.Row>
        </Form>
    )
}

export default SearchStore
