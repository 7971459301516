import React from 'react'
// import './detail.scss'

const StoreLocationDetail = ({ data }) => {
    console.log('Cek Data Store: ', data)
    
    const EmptyImage = ({ image, title }) => {
        return (
            <div className="logo-store">
                {image && <img src={image} alt={title} />}
            </div>
        )
    }

    const StoreItem = ({ content }) => {
        const { category, title, url_online, url_offline } = content

        const renderLinks = () => {
            if (category === 'online & offline') {
                return (
                    <>
                        <a
                            className="text-link"
                            href={url_online}
                            target="_blank"
                        >
                            Website Link
                        </a>
                        <a
                            className="text-link"
                            href={url_offline}
                            target="_blank"
                        >
                            Get Direction
                        </a>
                    </>
                )
            } else if (category === 'offline') {
                return (
                    <a className="text-link" href={url_offline} target="_blank">
                        Get Direction
                    </a>
                )
            } else if (category === 'online') {
                return (
                    <a className="text-link" href={url_online} target="_blank">
                        Website Link
                    </a>
                )
            }
            return null
        }

        return (
            <div className="box-item">
                <EmptyImage image={content.image} title={title} />
                <div className="box-title">
                    <div className="store-title mont-regular">{title}</div>
                </div>
                <div className="box-link mont-regular">{renderLinks()}</div>
            </div>
        )
    }

    const renderStores = () => {
        return data.map((content, index) => {
            const classNames = `col-12 col-sm-6 col-lg-4${
                content.country === 'Australia' ? ' active-country' : ''
            }`
            return (
                <div key={index} className={classNames}>
                    <StoreItem content={content} />
                </div>
            )
        })
    }

    return (
        <div className="box-bottom">
            <div className="row">
                {renderStores()}
            </div>
        </div>
    )
}

export default StoreLocationDetail
