import React, { useEffect, useState } from 'react'
import { withTrans } from '../../i18n/withTrans'
import { useTranslation } from 'react-i18next'

import MetaTags from '../MetaTags'
import Navigation from '../../components/Navigation'
import Footer from '../../components/Footer'
import CustomPagination from './CustomPagination'
import SearchStore from './SearchStore'

import './stores.scss'
import banner_desktop from '../../images/store/banner-stores-desktop.webp'
import banner_mobile from '../../images/store/banner-stores-mobile.webp'
import StoreLocationTabs from '../StoreLocationTabs'
import StoreLocationDetail from '../StoreLocationDetail'

const StoreLocation = ({ pageContext }) => {
    const DataStore = pageContext.storeData
    console.log('Cek Data Social', DataStore)
    const { t } = useTranslation()

    const [data, setData] = useState([]) // Initialize data state with an empty array
    const [currentPage, setCurrentPage] = useState(1) // Initialize current page state
    const [storesPerPage] = useState(6) // Set number of stores per page
    const [searchTerm, setSearchTerm] = useState('') // Initialize search term state

    useEffect(() => {
        // Filter active stores for Singapore and set the data state
        const activeStores = DataStore.filter(
            (store) => store.country === 'Australia'
        )
        setData(activeStores)
    }, [DataStore]) // Trigger effect when DataStore changes

    const menuCountry = [...new Set(DataStore.map((val) => val.country))]

    const filterCountry = (item) => {
        const newItem = DataStore.filter((newVal) => newVal.country === item)
        setData(newItem)
        setCurrentPage(1) // Reset to first page when filtering stores
    }

    // Pagination
    const indexOfLastStore = currentPage * storesPerPage
    const indexOfFirstStore = indexOfLastStore - storesPerPage
    const currentStores = data.slice(indexOfFirstStore, indexOfLastStore)

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    // Search function
    const searchHandler = (event) => {
        setSearchTerm(event.target.value)
        setCurrentPage(1) // Reset to first page when searching
    }

    const filteredStores = currentStores.filter((store) =>
        store.title.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const shouldShowPagination = data.length > storesPerPage

    return (
        <div className="stores-page">
            <MetaTags
                title="Ellips | Store Location"
                description={`${t('store.meta-desc')}`}
                image={banner_desktop}
                url={typeof window !== 'undefined' ? window.location.href : ''}
            />
            <Navigation activeLanguage={pageContext.lang} />
            <div className="stores-page-container">
                <div className="stores-container">
                    <img
                        src={banner_desktop}
                        alt="image"
                        className="img-fluid radius-bottom-right d-none d-md-block radius-bottom-right"
                    />
                    <img
                        src={banner_mobile}
                        alt="image"
                        className="img-fluid radius-bottom-right d-block d-md-none radius-bottom-right"
                    />
                    <div className="page-title text-focus-in">
                        <h1>Our Stores</h1>
                    </div>
                </div>

                <div className="box-content container">
                    <div className="row">
                        <div className="col-12 col-sm-3 col-lg-2 p-0">
                            <div className="stores-title">
                                <h1>Our Stores</h1>
                            </div>
                            <StoreLocationTabs
                                filterCountry={filterCountry}
                                setData={setData}
                                menuCountry={menuCountry}
                                activeLanguage={pageContext.lang}
                            />
                        </div>
                        <div className="col-12 col-sm-9 col-lg-10">
                            <div className="store-detail-container">
                                <div className="box-store">
                                    <div className="box-top">
                                        <div className="head-text mont-regular">
                                            Find Our Stores
                                        </div>
                                        <div className="desc-text mont-regular">
                                            ELLIPS products are sold
                                            internationally at online store and
                                            offline store.
                                        </div>
                                        <SearchStore
                                            search={searchHandler}
                                            searchTerm={searchTerm}
                                        />
                                    </div>
                                    <StoreLocationDetail
                                        data={filteredStores}
                                    />
                                    {shouldShowPagination && (
                                        <CustomPagination
                                            storesPerPage={storesPerPage}
                                            totalStores={data.length}
                                            paginate={paginate}
                                            currentPage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withTrans(StoreLocation)
