import React from 'react'
import { Pagination, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCaretLeft,
    faCaretRight,
} from '@fortawesome/free-solid-svg-icons'

const CustomPagination = ({
    storesPerPage,
    totalStores,
    paginate,
    currentPage,
}) => {
    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(totalStores / storesPerPage); i++) {
        pageNumbers.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => paginate(i)}
            >
                {i}
            </Pagination.Item>
        )
    }

    return (
        <Pagination className="justify-content-center mont-regular">
            <Pagination.Prev
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="arrow-page"
            >
                <FontAwesomeIcon icon={faCaretLeft} />
            </Pagination.Prev>
            {pageNumbers}
            <Pagination.Next
                onClick={() => paginate(currentPage + 1)}
                disabled={
                    currentPage === Math.ceil(totalStores / storesPerPage)
                }
                className="arrow-page"
            >
                <FontAwesomeIcon icon={faCaretRight} />
            </Pagination.Next>
        </Pagination>
    )
}

export default CustomPagination
